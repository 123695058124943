import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from '../components/Seo'
import { MDXRenderer } from "gatsby-plugin-mdx";

export default function BlogPost({ data, ...props }) {
  const post = data.mdx
  const image = data.allFile.edges.map(({ node }) => node).find(
    a => `${a.name}${a.ext}` === post.frontmatter.featured_image
  )?.childImageSharp?.gatsbyImageData.src
  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.description?post.description:post.excerpt}
        type="post"
        image={image}
        path={`/blog/${post.slug}`}
      />

<div className="bg-light pt-8 pb-5 pr-5 pl-5 md:pr-10 md:pl-10 mt-4 mb-4 max-w-full rounded-md prose">
        <h1>{post.frontmatter.title}</h1>
        <p>
        Posted: {post.frontmatter.date}
        </p>
        <MDXRenderer>{post.body}</MDXRenderer>
      </div>
    </Layout>
  );
}

export const query = graphql`query ($id: String) {
  allFile(filter: {relativeDirectory: {eq: "images"}}) {
    edges {
      node {
        relativePath
        name
        ext
        childImageSharp {
          gatsbyImageData(
            width: 200
            height: 180
            transformOptions: {cropFocus: CENTER}
            layout: FIXED
          )
        }
      }
    }
  }
  mdx(id: {eq: $id}) {
    excerpt
    body
    slug
    frontmatter {
      title
      layout
      title
      categories
      featured_image
      date(formatString: "MMMM DD, YYYY")
    }
  }
}
`;
